<template>
    <div class="flex justify-between">
        <StatusButton v-for="(count, status) in currentStatuses" :key="status" :active="selectedInvoiceType === status" @click="statusClicked(status)">
            <template #label>{{ capitalizeFirstLetter(status) }}</template>
            <template #count>{{ count == 0 ? "" : count }}</template>
        </StatusButton>
    </div>
</template>
<script>
import queryString from "query-string";
import Api from "../supplierInvoice.api";
export default {
    data() {
        return {
            selectedInvoiceType: "all",
            currentStatuses: [],
        };
    },

    components: {
        StatusButton: () => import("./StatusButton.vue"),
    },

    created() {
        if (!this.isGlobalClientSelected) this.getAll();
    },

    methods: {
        async getAll() {
            const { search, currency, clientId, dateFrom, dateTo } = queryString.parse(window.location.search, { sort: false });
            this.currentStatuses = await Api.getCounts(search, currency, clientId, dateFrom, dateTo);
            const query = queryString.parse(window.location.search, { sort: false });
            this.selectedInvoiceType = query.status ? query.status : "all";
        },

        statusClicked(status) {
            this.selectedInvoiceType = status;
            const query = queryString.parse(window.location.search, { sort: false });
            query.pageNumber = 1;
            query.status = status;
            this.$router.replace({ query }).catch(() => {});
            this.$emit("searchParametersChanged");
        },

        capitalizeFirstLetter(string) {
            const translation = {
                all: "Alla",
                inter: "Manuell",
                utkast: "Utkast",
                attesteras: "Atteseras",
                förbrukad: "Förbrukad",
                kreditFaktura: "Kreditfaktura",
                väntarBetalning: "Väntar betalning",
                betald: "Betalad",
                kvittoSkapat: "Kvitto skapat",
                nekad: "Nekad",
                adminNekad: "Admin Nekad",
                kompleteras: "Kompleteras",
                kompleterad: "Kompleterad",
            };
            return translation[string];
        },
    },

    computed: {
        isGlobalClientSelected() {
            return this.$store.getters["topBar/isClientSelected"];
        },
    },
};
</script>
